<template>
  <!-- Bar Chart for Daily Output -->
  <v-sheet
    v-if="dailyOutputData"
    :elevation="2"
    :height="225"
    class="pa-3 d-flex justify-center"
  >
    <bar-chart
      :title="chartTitle"
      :data="dailyOutputData"
      :responsive="true"
      :maintainAspectRatio="false"
      :height="200"
      width="100%"
    />
  </v-sheet>
</template>
<script>
const BarChart = () => import("@/components/charts/BarChart.vue");

export default {
  components: { BarChart },
  props: {
    type: {
      type: String, //valid options are ['input', 'output', 'packaging', 'receiving']
      required: true,
    },
    startDate: {
      type: String,
      required: true,
    },
    endDate: {
      type: String,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dailyOutputData: null,
      chartTitle: "",
      endpoints: {
        input: "/reports/manufacturing/daily-input",
        output: "/reports/manufacturing/daily-output",
        packaging: "/reports/packaging/daily-packaging",
        receiving: "/reports/receiving/daily-receiving",
      },
    };
  },

  computed: {
    watcherHelper() {
      return {
        type: this.type,
        startDate: this.startDate,
        endDate: this.endDate,
        product: this.product,
      };
    },
  },

  watch: {
    watcherHelper: {
      handler() {
        this.fetchDailyOutput();
      },
      deep: true,
    },
  },
  methods: {
    fetchDailyOutput() {
      let startDate = new Date(this.startDate);
      let endDate = new Date(this.endDate);

      if (startDate.getTime() === endDate.getTime()) {
        startDate.setDate(startDate.getDate() - 30);
      }

      const params = {
        start: startDate.toISOString().split("T")[0],
        end: this.endDate,
        productId: this.product.id,
      };

      this.$axios.get(this.endpoints[this.type], { params }).then((resp) => {
        const dailyOutputData = resp.data;

        const totalWeight = dailyOutputData.reduce((sum, o) => {
          sum += o.weight;
          return sum;
        }, 0);

        const chartData = {
          labels: [],
          datasets: [
            {
              label: `Weight (${this.$options.filters.formatNumber(
                totalWeight
              )})`,
              backgroundColor: "#f87979",
              data: [],
            },
          ],
        };

        const dateMap = dailyOutputData.reduce((map, output) => {
          map[output.date] = output.weight;
          return map;
        }, {});

        for (
          let date = startDate;
          date <= endDate;
          date.setDate(date.getDate() + 1)
        ) {
          const dateString = date.toISOString().split("T")[0];
          chartData.labels.push(dateString);
          chartData.datasets[0].data.push(dateMap[dateString] || 0);
        }

        this.dailyOutputData = chartData;
        this.chartTitle = `Daily ${
          this.type.charAt(0).toUpperCase() + this.type.slice(1)
        } for ${this.product.name}`;
      });
    },
  },
  mounted() {
    this.fetchDailyOutput();
  },
};
</script>
<style>
</style>